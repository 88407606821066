import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import "./src/styles/common.scss";
import "./src/styles/custom-property.css";
import "./src/styles/reset.scss";

const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY || '';

export const wrapRootElement = ({ element }) => {
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={RECAPTCHA_KEY}
		>
			{element}
		</GoogleReCaptchaProvider>
	)
}
